/*
 *
 * Application Main
 *
 */
!(function(APP) {
    'use strict';


    var breakpoint = 768;

    $('#navbar').on('show.bs.collapse', function () {
        // do something…
        $('.language').css({ visibility: 'visible' });
        $('.navbar-expand-md').css({ 'border-left': '1px solid #323232' });
    });

    $('#navbar').on('hide.bs.collapse', function () {
        // do something…
        $('.language').css({ visibility: 'hidden' });
        $('.navbar-expand-md').css({ 'border-left': 'none' });
    });

    $.extend(true, APP, {
        breakpoint: breakpoint,
        init: {
            headerFix: function() {

                // function check() {
                //     if (window.pageYOffset > sticky.offsetTop) {
                //         header.classList.add('sticky');
                //     } else {
                //         header.classList.remove('sticky');
                //     }
                // }

                // window.onscroll = APP.Helper.throttle(check, 250);
                // var header = document.getElementById('header');
                // var sticky = document.querySelector('.navbar');
            },
            autoPost: function() {

                $('select.autopost').each(function(i, element) {

                    var $form = $(element).closest('form');

                    $(element).on('change', function() {
                        $form.submit();
                    });
                });
            },
            select2: function() {
                $('select.select2').select2({
                    minimumResultsForSearch: Infinity
                });
            },
            map: function(opts) {

                // burda çok güzel bir özellik var ama haritaya para vermemek için kaldırıldı.
                if (opts === 987654321) {
                    // should always return;
                    return;
                }
                var map = document.getElementById('map');
                if (!map) {
                    return;
                }

                var mapObj = {
                    settings: {
                        el: map,
                        map: null,
                        template: document.getElementById('template-dagitim-bolgeleri').innerHTML,
                        compiledTemplate: null,
                        markers: [],
                        form: null,
                        contentPane: document.getElementById('dagitim-bolgeleri'),
                        data: {
                            Status: true,
                            Latitude: 41.015137,
                            Longitude: 28.979530,
                            LatLng: {}
                        }
                    },
                    elements: {},
                    init: function () {
                        this.moduleCache();
                        this.getGeoLocation();
                        this.eventListener();
                    },
                    moduleCache: function () {
                        this.elements.$map = $(this.settings.el);
                        this.elements.form = $('#map-form');
                        this.settings.compiledTemplate = Handlebars.compile(this.settings.template); // eslint-disable-line
                    },
                    getData: function (cb) {
                        var _self = this;

                        APP.Helper.sendRequest(_self.elements.form, {
                            method: opts.method,
                            url: opts.url
                        }, null).done(function (response) {
                            if (response.Result) {
                                if (response.Data.length > 0) {
                                    _self.settings.data.Latitude = null;
                                    _self.settings.data.Longitude = null;
                                }
                                $('#map').prev('.alert.alert-danger').remove();
                                _self.renderMarkers(response.Data, cb || function() {});
                            } else {
                                $('<div class="alert alert-danger">' + response.Error + '</div>').insertBefore($('#map'));
                            }
                        }).catch(function() {
                            // TODO: LOG HERE
                        });
                    },
                    eventListener: function () {
                        var _self = this;
                        var getDataFnWdebounce = APP.Helper.debounce(function() {
                            _self.getData();
                        }, 350);

                        _self.elements.form.on('submit', function(e) {
                            e.preventDefault();
                            getDataFnWdebounce();
                        });
                    },
                    currentLocation: function () {
                        var _self = this;
                        if (_self.settings.map) {
                            var panPoint = new google.maps.LatLng(_self.settings.data.Latitude, _self.settings.data.Longitude);
                            _self.settings.map.setZoom(13);
                            _self.settings.map.panTo(panPoint);
                        }
                    },
                    getGeoLocation: function () {
                        var _self = this;
                        _self.mapInit();
                        _self.render();
                    },
                    render: function (cb) {

                        var _self = this;
                        _self.getData(function() {
                            cb ? cb() : '';
                        });
                    },
                    renderMarkers: function (markersArr, cb) {

                        var _self = this;
                        _self.settings.markers.forEach(function(marker) {
                            marker.setMap(null);
                        });
                        _self.settings.markers = [];
                        var marker;
                        _self.settings.bounds = new google.maps.LatLngBounds();

                        var html = '';
                        $.each(markersArr, function (i) {
                            var LatLng = {
                                lat: parseFloat(this.lat.toString().replace(',', '.')),
                                lng: parseFloat(this.lng.toString().replace(',', '.'))
                            };

                            var icon = {
                                url: './assets/img/marker.svg',
                                size: new google.maps.Size(24, 24),
                                scaledSize: new google.maps.Size(24, 24),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(0, 24)
                            };

                            marker = new google.maps.Marker({
                                position: LatLng,
                                lat: LatLng.lat.toString().replace(',', '.'),
                                lng: LatLng.lng.toString().replace(',', '.'),
                                map: _self.settings.map,
                                icon: icon,
                                id: i,
                                title: this.title,
                                address: this.address,
                                phone: this.phone
                            });

                            var contentString = '<div class="infowindow-content">' +
                                '<div class="py-5 text-normal text-md">' +
                                    '<h6 class="mb-4">' + this.title + '</h6>' +
                                    '<p>' + this.address + '</p>' +
                                    '<p><span class="text-danger">Tel:</span> ' + this.phone + '</p>' +
                                '</div>';

                            //  '<a target="_blank" class="btn btn-block btn-outline-white" href="https://www.google.com/maps/dir/Current+Location/' + LatLng.lat.toString().replace(',', '.') + ',' + LatLng.lng.toString().replace(',', '.') + '">Yol Tarifi Al</a>' +

                            _self.settings.bounds.extend(marker.getPosition());
                            google.maps.event.addListener(_self.elements.infowindow, 'domready', function () {
                                jQuery('.gm-style-iw').prev('div').remove();
                                jQuery('.gm-style-iw').next('div').remove();
                                jQuery('.gm-style-iw').next('button').remove();
                                jQuery('.gm-ui-hover-effect').remove();
                            });

                            google.maps.event.addListener(marker, 'click', (function (marker) {
                                return function () {
                                    _self.settings.map.setCenter(marker.getPosition());
                                    _self.elements.infowindow.setContent(contentString);
                                    _self.elements.infowindow.open(_self.settings.map, marker);
                                    jQuery('.gm-style .gm-style-iw-a').addClass(marker.type);
                                };
                            })(marker));
                            _self.settings.markers.push(marker);

                            html += _self.settings.compiledTemplate(this);
                        });

                        _self.settings.contentPane.innerHTML = html;

                        _self.settings.map.addListener('click', function () {
                            _self.elements.infowindow.close();
                        });

                        if (_self.settings.markers.length > 0) {
                            if (_self.settings.data.Latitude) {
                                _self.currentLocation();
                            } else {
                                _self.settings.map.setCenter(_self.settings.bounds.getCenter());

                                if (_self.settings.markers.length > 1) {
                                    _self.settings.map.fitBounds(_self.settings.bounds);
                                }

                                _self.settings.map.setZoom(_self.settings.map.getZoom());
                            }
                        } else {
                            var lat = _self.settings.data.Latitude,
                                long = _self.settings.data.Longitude;

                            if (lat !== null && long !== null) {
                                _self.settings.map.setCenter(new google.maps.LatLng(lat, long));
                            }
                        }
                        cb.call(_self);
                    },
                    mapInit: function () {
                        var _self = this;

                        var $data = _self.settings.data;
                        _self.elements.infowindow = new google.maps.InfoWindow({ minWidth: 260, maxWidth: 400 });
                        _self.settings.bounds = new google.maps.LatLngBounds();
                        _self.settings.map = new google.maps.Map(_self.elements.$map[0], {
                            zoom: 10,
                            minZoom: 4,
                            center: { lat: $data.Latitude, lng: $data.Longitude },
                            /* styles: mapStyle, */
                            scrollwheel: true,
                            mapTypeControl: false,
                            panControl: false,
                            streetViewControl: false,
                            zoomControl: true,
                            contextmenu: true,
                            disableDefaultUI: true,
                            fullscreenControl: true
                        });
                    }
                };

                mapObj.init();
            },
            popUp: function() {
                var value = localStorage.getItem('popUp');
                if (value !== '1') {
                    var $content = $('#popup-content');
                    var $opts = $content.data('options');

                    if ( !$content.length ) {
                        return;
                    }

                    var options = $.extend({
                        src: '#popup-content',
                        type: 'inline'
                    }, $opts);

                    $.fancybox.open(options);
                }
            },
            popUpControl: function() {
                var acceptbtn = document.getElementById('onay');
                if (acceptbtn) {
                    acceptbtn.onclick = function() {
                        localStorage.setItem('popUp', '1');
                        $.fancybox.close();
                    };
                }
            },
            carouselControl: function () {
                $('[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
                    $($(e.target).attr('href') + ' .slick-initialized').slick('slickGoTo', 0);
                    $('.slick-initialized').slick('refresh');
                });
            }
        },

        INIT: function(options) {
            // APP init

            options = options || {};

            var fn;

            for (var i in this.init) {
                if ( Object.prototype.hasOwnProperty.call(this.init, i) && i.charAt(0) !== '_' && typeof(fn = this.init[i]) === 'function' ) {
                    fn.call(this, options && options[i] || options);
                }
            }

            return this;
        }
    });

})(window.APP = window.APP || {});

$(function() {
    'use strict';

    APP.browser = (function() {

        var is = APP.Helper.is,
            val, tmp,
            userAgent = APP.sanitizeXss(navigator.userAgent);

        var browser = {
            mobile: !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(userAgent),
            ios: !!/iPhone|iPad|iPod/i.test(userAgent),
            ie: (tmp = userAgent.toLowerCase().match(/MSIE ([^;]+)|rv:(11)\.[0-9]+/i)) ? parseInt(tmp[1] || tmp[2], 10) : false,
            edge: (tmp = userAgent.indexOf('Edge/')) > 0 ? parseInt(userAgent.substring(tmp + 5, userAgent.indexOf('.', tmp)), 10) : false,
            bp: function() {
                return $(window).width() < APP.breakpoint;
            }
        };

        var $el = $('html'); // document.documentElement

        for (var k in browser ) {
            if ( Object.prototype.hasOwnProperty.call(browser, k) ) {
                val = browser[k];

                if ( val && !is.function(val) ) {
                    $el.addClass(k);
                    if ( !is.boolean(val) ) {
                        $el.addClass(k + val);
                    }
                }
            }
        }

        APP.browser = browser;

        return browser;
    }());

    APP.scriptPath = APP.Helper.getScriptPath(['app.js', 'app.min.js', 'main.js']);

    APP.Helper.loadScript(APP.scriptPath + 'config.js', {
        success: function() {
            APP.INIT(CONFIG);
        },
        failed: function() {
            APP.INIT();
        }
    });
});
