if ($(window).width() < 768) {
    $('.carousel-date').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 3,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.85,
                    slidesToScroll: 3
                }
            }
        ]
    });
}

